@import "~bootstrap/dist/css/bootstrap.css";
@import "~ngx-toastr/toastr";

body {
  margin: 0px !important;
  background-color: #f2f2f2;
}

.btn:focus {
  box-shadow: none;
}

.btn:hover {
  box-shadow: none;
}

.form-control {
  padding: 2px 8px;
  border-radius: 50px;
  border: none;
  font-size: 12px;
}

.p-button-label {
  font-weight: 400;
}

.p-multiselect-item {
  font-weight: 400;
  font-size: 12px;
}

.p-multiselect-filter {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background: #eaeaea !important;
  color: black;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  box-shadow: inset 0 0 0 0.15rem #8dcdff00 !important;
}

.p-multiselect-label {
  font-size: 14px;
  padding: 0px;
}

.p-multiselect .p-multiselect-label.p-placeholder {
  color: #6c757d;
  padding: 5px 9px;
  font-size: 12px;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  margin: 0;
  font-size: 12px;
  padding: 4px 10px;
}

.p-checkbox .p-checkbox-box {
  border: 2px solid #ced4da;
  background: #ffffff;
  width: 16px;
  height: 16px;
}

.p-checkbox {
  align-items: center;
}

.p-multiselect-panel .p-multiselect-items:not(.p-multiselect-virtualscroll) {
  padding: 0px;
}

.p-multiselect {
  border-radius: 50px;
}

.cust-form-label {
  font-size: 12px;
  margin-bottom: 3px;
  display: block;
  color: #fff;
}

.color-white {
  color: white;
  padding-bottom: 10px;
}

.cust-form-group {
  justify-content: end;
  flex-direction: column;
  padding: 0.3rem 0.7rem;
}

.save-btn {
  border-radius: 0px !important;
  background-color: #ff9100;
  width: 140px;
  color: white !important;
  margin: 0px !important;
  font-size: 12px !important;
  padding: 4px 8px;
}

.submit-btn {
  width: 140px;
  color: white !important;
  border-radius: 50px !important;
  background-color: #219fd2 !important;
  border-top-left-radius: 0px !important;
  margin: 0px !important;
  border-bottom-left-radius: 0px !important;
  font-size: 12px !important;
  padding: 4px 8px;
}

.custom-sidenav {
  height: -moz-available;
  /* height: -webkit-fill-available; */
  overflow-y: auto;
  overflow-x: hidden;
  width: 50%;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14),
    0px 2px 4px -1px rgba(0, 0, 0, 0.2);
  z-index: 1;
  background: linear-gradient(180deg, #0096bd, #005178);
  position: absolute;
  border-radius: 0px;
  border-top-right-radius: 20px !important;
  border-top-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  padding: 20px;
  overflow: overlay;
}

.add-btn {
  color: white;
  background-color: #219fd2;
  font-size: 12px;
  padding: 4px 20px;
  border-radius: 50px;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.clear-btn {
  width: 140px;
  color: white !important;
  background-color: #828282 !important;
  margin: 0px !important;
  border-radius: 50px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  font-size: 12px !important;
  padding: 4px 8px;
}

.cancel-btn {
  width: 140px;
  color: white !important;
  background-color: #828282 !important;
  margin: 0px !important;
  border-radius: 50px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  font-size: 12px !important;
  padding: 4px 8px;
}

.excelButton {
  color: white;
  background-color: #ff9100;
  font-size: 12px;
  padding: 4px 20px;
  border-radius: 50px;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.header-label {
  font-size: 24px;
  font-weight: 400;
}

.body-gutter-spacing {
  padding: 0px 20px;
}

.side-padding {
  padding-left: 6%;
  padding-right: 6%;
  padding-bottom: 100px;
}

.p-datepicker table td {
  padding: 0px !important;
  font-size: 12px;
}

.p-datepicker table td > span {
  padding: 0px !important;
  width: 20px !important;
  height: 20px !important;
}

html {
  overflow-y: overlay;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: none;
}

::-webkit-scrollbar-corner {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #e3e3e3;
  border-radius: 10px;
}

.img-logo {
  width: 153px !important;
}

a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.p-calendar-w-btn .p-datepicker-trigger {
  background: white;
  color: black;
  border-radius: 50px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: 1px solid white;
}

.p-calendar-w-btn .p-inputtext {
  border: 1px solid white;
}

.p-calendar-w-btn .p-inputtext:hover {
  border: 1px solid white;
}

.p-calendar-w-btn .p-inputtext:focus {
  box-shadow: none;
  outline: none;
  border: 1px solid white;
}

.p-button:enabled:hover {
  background: white;
  color: black;
  border-color: white;
}

.p-button:focus {
  box-shadow: none;
  outline: none;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

@media (min-width: 1000px) {
  .container {
    min-width: 920px;
  }
}

@media (min-width: 1100px) {
  .container {
    min-width: 1020px;
  }
}

@media (min-width: 1200px) {
  .container {
    min-width: 1120px;
  }
}

@media (min-width: 1300px) {
  .container {
    min-width: 1220px;
  }
}

@media (min-width: 1400px) {
  .container {
    min-width: 1320px;
  }
}

@media (min-width: 1500px) {
  .container {
    width: 1420px;
  }
}

@media (min-width: 1600px) {
  .container {
    min-width: 1520px;
  }
}

@media (min-width: 1700px) {
  .container {
    min-width: 1620px;
  }
}

@media (min-width: 1800px) {
  .container {
    min-width: 1720px;
  }
}

@media (min-width: 1900px) {
  .container {
    min-width: 1820px;
  }
}

#showCustomDialogForm .p-dialog .p-dialog-header {
  border: none;
  background: transparent;
}

#showCustomDialogForm .p-dialog .p-dialog-header .p-dialog-header-icon {
  display: none;
}

#showCustomDialogForm .p-dialog {
  border: none;
}

#showCustomDialogForm .p-dialog .p-dialog-content {
  background: transparent;
}

.p-calendar .p-inputtext {
  border-radius: 50px;
  font-size: 12px;
}

.pi-file-excel {
  font-size: 12px;
  margin-right: 5px;
}

.modal-backdrop-custom {
  pointer-events: none;
  opacity: 0.5;
}

.add-poniter-all {
  pointer-events: all;
}

@media (max-width: 767px) {
  .side-padding {
    padding-left: 10px;
    padding-right: 15px;
  }

  .submit-btn,
  .cancel-btn,
  .add-btn,
  .excelButton {
    width: 50%;
  }

  .mobileButtonClass {
    position: fixed;
    bottom: 39px;
    left: 0px;
    padding: 0px;
    z-index: 2;
  }

  .bottom-class {
    bottom: 14px;
  }

  #showGridClass .p-multiselect {
    width: 100% !important;
    text-align: left !important;
  }

  .custom-sidenav {
    padding: 0px 30px;
    width: 96% !important;
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 840px) {
  .custom-sidenav {
    width: 60%;
  }
}
